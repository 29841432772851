#SymphonyAdmin{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 200px 3fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas: 'header header' 'side content';
    &>aside{
        grid-area: side;
        background-color: #02446e;
        color: white;
        padding: 0 20px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        z-index: 100;
        &>ul{
            list-style: none;
            padding: 0;
            &>li{
                height: 50px;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover{
                    background-color: #1a5d7a;
                }
                border-bottom: 1px solid #1a5d7a;
            }
        }
    }
    &>header{
        grid-area: header;
        background-color: #f5f5f5;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        z-index: 100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
    }
    &>main{
        grid-area: content;
        padding: 5px;
        overflow-y: auto;
        position: relative;
        &>.fullsize{
            width: 100%;
            height: 100%;
        }
    }
}