.dashboard{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    gap: 10px 0px;
    &>.filters{
        display: flex;
        flex-direction: column;
        align-items: left;
        gap: 10px;
        margin-top: 10px;
        &>.dateFilter{
            display: flex;
            gap: 0px 10px;
        }
        &>.ModCounter{
            display: flex;
            gap: 0px 10px;
        }
    }
    &>.acctions{

    }
    &>.data{
        &>table{
            width: 100%;
            border: 1px solid #ccc;
            border-collapse: collapse;
            table-layout: auto;
            &>thead{
                background-color: #02446e;
                color: white;
                &>tr{
                    &>th{
                        padding: 5px;
                        border: 1px solid #ccc;
                    }
                }
            }
            &>tbody{
                &>tr{
                    &:nth-child(even){
                        background-color: #f5f5f5;
                    }
                    &>td{
                        width: 1%;
                        min-width: 50px;
                        padding: 5px;
                        border: 1px solid #ccc;
                    }
                    &>.PatientName{
                        width: auto;
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &>.PatientID{
                        width: 1%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

input{
    height: 30px;
    border: none;
    border-bottom: 1px solid #ccc;
    &:focus {
        outline: none;
        border-bottom: 1px solid #02446e;
    }
}

button{
    height: 30px;
    border: none;
    background-color: #02446e;
    color: white;
    cursor: pointer;
    padding: 0 15px;
    &:hover{
        background-color: #1a5d7a;
    }
}